import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

const initialState = {
    users: []
}

const users = (state = initialState.users, action) => {
    switch (action.type) {        
        case actionTypes.GET_USER_LIST:
            return action.users;

        case actionTypes.EDIT_USER:
            var newState = [...state];
            var userIndex = newState.findIndex(u => u.idUser === action.user.idUser);
            if (userIndex > -1) {
                var newUser = structuredClone(newState[userIndex]);
                newUser.role = action.user.role;
                newUser.storeUrl = action.user.storeUrl;
                newUser.storeToken = action.user.storeToken;
                newUser.eanAttribute = action.user.eanAttribute;
                newState[userIndex] = newUser;
            }
            return newState;

        case actionTypes.DELETE_USER:
            var newState = [...state];
            var userIndex = newState.findIndex(u => u.idUser === action.idUser);
            if (userIndex > -1) {
                newState.splice(userIndex, 1);
            }
            return newState;

        case actionTypes.ADD_USER:
            var newState = [...state];
            if (action.user) {
                newState.push(action.user);
            }
            return newState;

        case actionTypes.ADD_USER_CREDITS:
            var newState = [...state];
            var userIndex = newState.findIndex(u => u.idUser === action.data.idUser);
            console.log(newState);
            console.log(action.data);
            if (userIndex > -1) {
                newState[userIndex].credits = parseInt(newState[userIndex].credits) + parseInt(action.data.credits);
            }
            return newState;

        default:
            return state;
    }
};

const reducer = combineReducers({
    users
});

export default reducer;