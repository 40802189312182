import * as actionTypes from "./actionTypes";
import * as panelService from "../../api/services/panelService";

export const getPageProductsCompleted = (data) => ({
    type: actionTypes.GET_PRODUCTS,
    pageProducts: data,
});

export const getProductByIdCompleted = (data) => ({
    type: actionTypes.GET_PRODUCT_BY_ID,
    product: data,
});

export const searchProductsCompleted = (data) => ({
    type: actionTypes.GET_SEARCH_PRODUCTS,
    searchProducts: data
})

export const cleanSearchProductsCompleted = () => ({
    type: actionTypes.CLEAN_SEARCH_PRODUCTS
})

export const getPageProducts = (page, verified, onSuccess) => (dispatch) => {
    panelService.getPageProducts(page, verified, (response) => {
        dispatch(getPageProductsCompleted(response));
        onSuccess();
    });
};

export const getProductById = (id, onSuccess) => (dispatch) => {
    panelService.getProductById(id, (response) => {
        dispatch(getProductByIdCompleted(response));
        onSuccess();
    });
};

export const searchProducts = (searchString, onSuccess) => (dispatch) => {
    panelService.searchProducts(searchString, (response) => {
        dispatch(searchProductsCompleted(response));
        onSuccess();
    });
};

export const cleanSearchProducts = (onSuccess) => (dispatch) => {
    dispatch(cleanSearchProductsCompleted());
    onSuccess();
};

export const purgeProduct = (productId, onSuccess) => (dispatch) => {
    panelService.purgeProduct(productId, (response) => {
        //dispatch(purgeProductCompleted(productId));
        onSuccess();
    });
};

// <product>
export const addNewProduct = (formData, onSuccess) => (dispatch) => {
    panelService.addNewProduct(formData, (response) => {
        onSuccess(response);
    });
}

export const deleteProduct = (idProduct, onSuccess) => (dispatch) => {
    panelService.deleteProduct(idProduct, (response) => {
        onSuccess();
    });
}

export const updateProductCompleted = (productData) => ({
    type: actionTypes.UPDATE_PRODUCT,
    product: productData
})

export const updateProduct = (productData, onSuccess) => (dispatch) => {
    panelService.updateProduct(productData, (response) => {
        dispatch(updateProductCompleted(productData));
        onSuccess(response);
    });
}
// </product>

// <title>
export const addNewProductTitleCompleted = (data) => ({
    type: actionTypes.ADD_PRODUCT_TITLE,
    title: data
});

export const updateProductTitleCompleted = (data) => ({
    type: actionTypes.UPDATE_PRODUCT_TITLE,
    title: data
});

export const deleteProductTitleCompleted = (data) => ({
    type: actionTypes.DELETE_PRODUCT_TITLE,
    title: data
});

export const addNewProductTitle = (formData, onSuccess) => (dispatch) => {
    panelService.addNewProductTitle(formData, (response) => {
        dispatch(addNewProductTitleCompleted(response));
        onSuccess();
    });
};

export const updateProductTitle = (idTitle, newTitle, onSuccess) => (dispatch) => {
    panelService.updateProductTitle(idTitle, newTitle, (response) => {
        dispatch(updateProductTitleCompleted({ idTitle: idTitle, title: newTitle }));
        onSuccess();
    });
};

export const deleteProductTitle = (idTitle, onSuccess) => (dispatch) => {
    panelService.deleteProductTitle(idTitle, (response) => {
        dispatch(deleteProductTitleCompleted({ idTitle: idTitle }));
        onSuccess();
    });
};
// </title>

// <description>
export const addNewProductDescriptionCompleted = (data) => ({
    type: actionTypes.ADD_PRODUCT_DESCRIPTION,
    description: data
});

export const updateProductDescriptionCompleted = (data) => ({
    type: actionTypes.UPDATE_PRODUCT_DESCRIPTION,
    description: data
});

export const deleteProductDescriptionCompleted = (data) => ({
    type: actionTypes.DELETE_PRODUCT_DESCRIPTION,
    description: data
});

export const addNewProductDescription = (formData, onSuccess) => (dispatch) => {
    panelService.addNewProductDescription(formData, (response) => {
        dispatch(addNewProductDescriptionCompleted(response));
        onSuccess();
    });
};

export const updateProductDescription = (idDescription, newDescription, onSuccess) => (dispatch) => {
    panelService.updateProductDescription(idDescription, newDescription, (response) => {
        dispatch(updateProductDescriptionCompleted({ idDescription: idDescription, description: newDescription }));
        onSuccess();
    });
};

export const deleteProductDescription = (idDescription, onSuccess) => (dispatch) => {
    panelService.deleteProductDescription(idDescription, (response) => {
        dispatch(deleteProductDescriptionCompleted({ idDescription: idDescription }));
        onSuccess();
    });
};
// </description>

// <image>
export const addNewProductImageCompleted = (data) => ({
    type: actionTypes.ADD_PRODUCT_IMAGE,
    image: data
});

export const deleteProductImageCompleted = (data) => ({
    type: actionTypes.DELETE_PRODUCT_IMAGE,
    image: data
});

export const addNewProductImage = (formData, onSuccess) => (dispatch) => {
    panelService.addNewProductImage(formData, (response) => {
        dispatch(addNewProductImageCompleted(response));
        onSuccess();
    });
};

export const deleteProductImage = (idImage, onSuccess) => (dispatch) => {
    panelService.deleteProductImage(idImage, (response) => {
        dispatch(deleteProductImageCompleted({ idImage: idImage }));
        onSuccess();
    });
};
// </image>