export const GET_PRODUCTS = 'products/page/get';
export const GET_PRODUCT_BY_ID = 'products/id/get';
export const GET_SEARCH_PRODUCTS = 'products/search/get';
export const CLEAN_SEARCH_PRODUCTS = 'products/search/clean';
export const UPDATE_PRODUCT = 'products/update';

export const ADD_PRODUCT_TITLE = 'product/title/add';
export const UPDATE_PRODUCT_TITLE = 'product/title/update';
export const DELETE_PRODUCT_TITLE = 'product/title/delete';

export const ADD_PRODUCT_DESCRIPTION = 'product/description/add';
export const UPDATE_PRODUCT_DESCRIPTION = 'product/description/update';
export const DELETE_PRODUCT_DESCRIPTION = 'product/description/delete';

export const ADD_PRODUCT_IMAGE = 'product/image/add';
export const DELETE_PRODUCT_IMAGE = 'product/image/delete';