import { config, appFetch, setCookieInfo } from "../appFetch";

export const makeLogin = (username, password, onSuccess, onErrors) => appFetch("/user/function::login", config("POST", { username, password }), res => {
    if (res.token && res.role) {
        setCookieInfo(res.token, username, res.role, 30);

        setTimeout(() => {
            onSuccess(res);
        }, 1000)
    } else {
        onSuccess(res);
    }
}, onErrors);

export const getUserToken = (username, password, onSuccess, onErrors) => appFetch("/user/function::login", config("POST", { username, password }), onSuccess, onErrors);