import { combineReducers } from 'redux';

import panel from '../modules/panel';
import login from '../modules/login';
import admin from '../modules/admin';
import users from '../modules/users';
import logs from '../modules/logs';

const reducer = combineReducers({
  panel: panel.reducer,
  login: login.reducer,
  admin: admin.reducer,
  users: users.reducer,
  logs: logs.reducer
});

export default reducer;
