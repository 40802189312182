import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

const initialState = {
    pageImages: {
        images: [],
        page: 1,
        imagesCount: 0
    },

    pageTitles: {
        titles: [],
        page: 1,
        titlesCount: 0
    },

    pageDescriptions: {
        titles: [],
        page: 1,
        descriptionsCount: 0
    },
}

const pageImages = (state = initialState.pageImages, action) => {
    switch (action.type) {
        case actionTypes.GET_PAGE_IMAGES:
            return action.pageImages;
        default:
            return state;
    }
};

const pageTitles = (state = initialState.pageTitles, action) => {
    switch (action.type) {
        case actionTypes.GET_PAGE_TITLES:
            return action.pageTitles;
        default:
            return state;
    }
};

const pageDescriptions = (state = initialState.pageDescriptions, action) => {
    switch (action.type) {
        case actionTypes.GET_PAGE_DESCRIPTIONS:
            return action.pageDescriptions;
        default:
            return state;
    }
};


const reducer = combineReducers({
    pageImages,
    pageTitles,
    pageDescriptions
});

export default reducer;