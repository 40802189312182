/* eslint-disable indent */
import NetworkError from './NetworkError';

const COOKIE_TOKEN_LOGIN = 'tokenLogin';
const COOKIE_NAME_LOGIN = 'nameLogin';
const COOKIE_USER_ROLE = 'userRole';

let networkErrorCallback;
let reauthenticationCallback;
reauthenticationCallback = () => {
    removeCookieInfo();
    window.location.replace("/login");
}

const isJson = (response) => {
  const contentType = response.headers.get('content-type');
  return contentType && contentType.indexOf('application/json') !== -1;
};

const handleOkResponse = (response, onSuccess) => {
  if (!response.ok) {
    return false;
  }
  if (!onSuccess) {
    return true;
  }
  if (response.status === 204) {
    onSuccess();
    return true;
  }
  if (isJson(response)) {
    response.json().then((payload) => onSuccess(payload));
  } else {
    onSuccess();
  }

  return true;
};

const handle4xxResponse = (response, onErrors) => {
  if (response.status < 400 || response.status >= 500) {
    return false;
  }
  if (response.status === 401 && reauthenticationCallback) {
    reauthenticationCallback();
    return true;
  }
  if (!isJson(response)) {
    throw new NetworkError();
  }
  if (onErrors) {
    response.json().then((payload) => {
      if (payload.code || payload.fieldErrors) {
        onErrors(payload);
      } else {
        onErrors('Fatal Error');
      }
    });
  }

  return true;
};

const handleResponse = (response, onSuccess, onErrors) => {
  if (handleOkResponse(response, onSuccess)) {
    return;
  }
  if (handle4xxResponse(response, onErrors)) {
    return;
  }
  throw new NetworkError();
};

export const init = (callback) => (networkErrorCallback = callback);

export const setReauthenticationCallback = (callback) =>
  (reauthenticationCallback = callback);


export const setCookieInfo = (token, username, user_role, time) => {
  setCookie(COOKIE_TOKEN_LOGIN, token, time);
  setCookie(COOKIE_NAME_LOGIN, username, time);
  setCookie(COOKIE_USER_ROLE, user_role, time);
};

export const getCookieTokenLogin = () => getCookie(COOKIE_TOKEN_LOGIN);
export const getCookieNameLogin = () => getCookie(COOKIE_NAME_LOGIN);
export const getCookieUserRole = () => getCookie(COOKIE_USER_ROLE);

export const removeCookieInfo = () => {
  setCookie(COOKIE_TOKEN_LOGIN, '', -1);
  setCookie(COOKIE_NAME_LOGIN, '', -1);
  setCookie(COOKIE_USER_ROLE, '', -1);
};


export const config = (method, body) => {
  const config = {};
  config.method = method;
  if (body) {
    if (body instanceof FormData) {
      config.body = body;
    } else {
      config.headers = {'Content-Type': 'application/json'};
      config.body = JSON.stringify(body);
    }
  }

  const serviceToken = getCookieTokenLogin();
  
  if (serviceToken) {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${serviceToken}`;
    } else {
      config.headers = {Authorization: `Bearer ${serviceToken}`};
    }
  }
  return config;
};

export const appFetch = (path, options, onSuccess, onErrors) => {
  fetch(`${process.env.API_URL}${path}`, options)
    .then((response) => {
      try {
        handleResponse(response, onSuccess, onErrors);
      } catch (error) {
        onErrors(error);
      }
    })
    .catch(networkErrorCallback);
};

const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    //d.setTime(d.getTime() + exdays * 60 * 1000);

    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;SameSite=Strict';
};

const getCookie = (cname) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
