import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

const initialState = {
    pageLogs: {
        logs: [],
        page: 1,
        logsCount: 0
    },
    userRequests: [],
    pageReports: {
        reports: [],
        page: 1,
        reportsCount: 0
    },
    pageNotFound: {
        notFound: [],
        page: 1,
        notFoundCount: 0
    }
}

const pageLogs = (state = initialState.pageLogs, action) => {
    switch (action.type) {        
        case actionTypes.GET_LOGS:
            return action.pageLogs;
        default:
            return state;
    }
};

const userRequests = (state = initialState.userRequests, action) => {
    switch (action.type) {        
        case actionTypes.GET_REQUESTS:
            return action.userRequests;
        default:
            return state;
    }
};

const pageReports = (state = initialState.pageReports, action) => {
    switch (action.type) {        
        case actionTypes.GET_REPORTS:
            return action.pageReports;
        default:
            return state;
    }
};

const pageNotFound = (state = initialState.pageNotFound, action) => {
    switch (action.type) {        
        case actionTypes.GET_NOT_FOUND:
            return action.pageNotFound;
        case actionTypes.UPDATE_NOT_FOUND_FIXED:
            const notFoundCopy = [...state.notFound];
            const notFoundCopyIndex = notFoundCopy.findIndex(r => r.idReport === action.idReport);
            notFoundCopy.splice(notFoundCopyIndex, 1);
            return {
                notFound: notFoundCopy,
                page: state.page,
                notFoundCount: state.notFoundCount - 1
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    pageLogs,
    userRequests,
    pageReports,
    pageNotFound
});

export default reducer;