import * as actionTypes from "./actionTypes";
import * as loginService from "../../api/services/loginService";

export const makeLoginCompleted = (data) => ({
    type: actionTypes.LOGIN_SUCCESS,
    loginInfo: data
})

export const makeLogin = (username, password, onSuccess, onErrors) => (dispatch) => {
    loginService.makeLogin(username, password, (response) => {
        dispatch(makeLoginCompleted(response));
        onSuccess(response)
    }, onErrors);
};


export const getUserToken = (username, password, onSuccess, onErrors) => (dispatch) => {
    loginService.getUserToken(username, password, (response) => {
        onSuccess(response)
    }, onErrors);
};