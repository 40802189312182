import { config, appFetch } from "../appFetch";

export const getPageImages = (page, onSuccess, onErrors) => appFetch(`/admin/function::getPageImages||page::${page}`, config("GET"), onSuccess, onErrors);
export const getPageImagesByStatus = (page, status, onSuccess, onErrors) => appFetch(`/admin/function::getPageImagesByStatus||page::${page}||status::${status}`, config("GET"), onSuccess, onErrors);

export const updateImageStatus = (img_id, status, onSuccess, onErrors) => appFetch("/admin/function::updateImageStatus", config("POST", { img_id, status }), onSuccess, onErrors);

export const getPageTitles = (page, onSuccess, onErrors) => appFetch(`/admin/function::getPageTitles||page::${page}`, config("GET"), onSuccess, onErrors);
export const updateProductTitle = (title_id, onSuccess, onErrors) => appFetch("/admin/function::updateProductTitle", config("POST", { title_id }), onSuccess, onErrors);

export const getPageDescriptions = (page, onSuccess, onErrors) => appFetch(`/admin/function::getPageDescriptions||page::${page}`, config("GET"), onSuccess, onErrors);
export const updateProductDescription = (description_id, onSuccess, onErrors) => appFetch("/admin/function::updateProductDescription", config("POST", { description_id }), onSuccess, onErrors);
export const updateDescription = (id_description, description_text, onSuccess, onErrors) => appFetch("/admin/function::updateDescription", config("POST", { id_description, description_text }), onSuccess, onErrors);