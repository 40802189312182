import * as actionTypes from "./actionTypes";
import * as adminService from "../../api/services/adminService";

export const getPageImagesCompleted = (data) => ({
    type: actionTypes.GET_PAGE_IMAGES,
    pageImages: data
})

export const getPageImages = (page, onSuccess, onErrors) => (dispatch) => {
    adminService.getPageImages(page, (response) => {
        dispatch(getPageImagesCompleted(response));
        onSuccess(response)
    }, onErrors);
};

export const getPageImagesByStatus = (page, status, onSuccess, onErrors) => (dispatch) => {
    adminService.getPageImagesByStatus(page, status, (response) => {
        dispatch(getPageImagesCompleted(response));
        onSuccess(response)
    }, onErrors);
};

export const updateImageStatus = (img_id, status, onSuccess, onErrors) => (dispatch) => {
    adminService.updateImageStatus(img_id, status, (response) => {
        onSuccess(response)
    }, onErrors);
};

export const getPageTitlesCompleted = (data) => ({
    type: actionTypes.GET_PAGE_TITLES,
    pageTitles: data
})

export const getPageTitles = (page, onSuccess, onErrors) => (dispatch) => {
    adminService.getPageTitles(page, (response) => {
        dispatch(getPageTitlesCompleted(response));
        onSuccess(response)
    }, onErrors);
};

export const updateProductTitle = (title_id, onSuccess, onErrors) => (dispatch) => {
    adminService.updateProductTitle(title_id, (response) => {
        onSuccess(response)
    }, onErrors);
};

export const getPageDescriptionsCompleted = (data) => ({
    type: actionTypes.GET_PAGE_DESCRIPTIONS,
    pageDescriptions: data
})

export const getPageDescriptions = (page, onSuccess, onErrors) => (dispatch) => {
    adminService.getPageDescriptions(page, (response) => {
        dispatch(getPageDescriptionsCompleted(response));
        onSuccess(response)
    }, onErrors);
};

export const updateProductDescription = (description_id, onSuccess, onErrors) => (dispatch) => {
    adminService.updateProductDescription(description_id, (response) => {
        onSuccess(response)
    }, onErrors);
};

export const updateDescription = (description_id, description_text, onSuccess, onErrors) => (dispatch) => {
    adminService.updateDescription(description_id, description_text, (response) => {
        onSuccess(response)
    }, onErrors);
};