import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

const initialState = {
    pageProducts: {
        products: [],
        page: 1,
        counter: 0
    },
    product: null,
    searchProducts: []
}

const pageProducts = (state = initialState.pageProducts, action) => {
    switch (action.type) {
        case actionTypes.GET_PRODUCTS:
            return action.pageProducts;
        default:
            return state;
    }
};

const product = (state = initialState.product, action) => {
    switch (action.type) {
        case actionTypes.GET_PRODUCT_BY_ID:
            return action.product;
        
        case actionTypes.UPDATE_PRODUCT:
            var newState = structuredClone(state);
            newState.product.category = action.product.category;
            newState.product.composition = action.product.composition;
            newState.product.ean = action.product.ean;
            newState.product.sku = action.product.sku;
            newState.product.format = action.product.format;
            newState.product.manufacturer = action.product.manufacturer;
            newState.product.size = action.product.size;
            newState.product.usageMethod = action.product.usageMethod;
            newState.product.verified = action.product.verified;
            return newState;

        case actionTypes.ADD_PRODUCT_TITLE:   
            var newState = structuredClone(state);
            newState.product.titles.push(action.title);
            return newState;
        case actionTypes.UPDATE_PRODUCT_TITLE:   
            var newState = structuredClone(state);
            var titleIndex = newState.product.titles.findIndex(t => t.idTitle === action.title.idTitle);
            newState.product.titles[titleIndex].title = action.title.title;
            return newState;
        case actionTypes.DELETE_PRODUCT_TITLE:   
            var newState = structuredClone(state);
            var titleIndex = newState.product.titles.findIndex(t => t.idTitle === action.title.idTitle);
            newState.product.titles.splice(titleIndex, 1);
            return newState;

        case actionTypes.ADD_PRODUCT_DESCRIPTION:   
            var newState = structuredClone(state);
            newState.product.descriptions.push(action.description);
            return newState;
        case actionTypes.UPDATE_PRODUCT_DESCRIPTION:   
            var newState = structuredClone(state);
            var descriptionIndex = newState.product.descriptions.findIndex(t => t.idDescription === action.description.idDescription);
            newState.product.descriptions[descriptionIndex].description = action.description.description;
            return newState;
        case actionTypes.DELETE_PRODUCT_DESCRIPTION:   
            var newState = structuredClone(state);
            var descriptionIndex = newState.product.descriptions.findIndex(t => t.idDescription === action.description.idDescription);
            newState.product.descriptions.splice(descriptionIndex, 1);
            return newState;

        case actionTypes.ADD_PRODUCT_IMAGE:   
            var newState = structuredClone(state);
            newState.product.images.push(action.image);
            return newState;
        case actionTypes.DELETE_PRODUCT_IMAGE:   
            var newState = structuredClone(state);
            var imageIndex = newState.product.images.findIndex(t => t.idImage === action.image.idImage);
            newState.product.images.splice(imageIndex, 1);
            return newState;

        default:
            return state;
    }
};

const searchProducts = (state = initialState.searchProducts, action) => {
    switch (action.type) {
        case actionTypes.GET_SEARCH_PRODUCTS:

            // BORRAR!!!
            // BORRAR!!!
            // BORRAR!!!
            // BORRAR!!!
            // BORRAR!!!
            // BORRAR!!!
            const p = action.searchProducts.products.map(p => {
                return {
                    idProduct: p.idProduct,
                    title: p.titles[0]?.title,
                    image: p.images[0]?.filename,
                    multipleImages: p.images.length > 1,
                    verified: p.verified
                }
            })
            return { products: p };

        case actionTypes.CLEAN_SEARCH_PRODUCTS:
            return [];
        default:
            return state;
    }
};


const reducer = combineReducers({
    pageProducts,
    product,
    searchProducts
});

export default reducer;