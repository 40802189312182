import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import "../styles/global.less";

const Body = lazy(() => import('./Body'));

function App() {

    return (
        <Suspense>
            <BrowserRouter>
                <Body />
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
