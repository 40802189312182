import * as actionTypes from "./actionTypes";
import * as usersService from "../../api/services/usersService";

export const getUsersCompleted = (data) => ({
    type: actionTypes.GET_USER_LIST,
    users: data.users
})

export const editUserCompleted = (data) => ({
    type: actionTypes.EDIT_USER,
    user: data.user
})

export const deleteUserCompleted = (data) => ({
    type: actionTypes.DELETE_USER,
    idUser: data.idUser
})

export const addUserCompleted = (data) => ({
    type: actionTypes.ADD_USER,
    user: data.user
})

export const addUserCreditsCompleted = (data) => ({
    type: actionTypes.ADD_USER_CREDITS,
    data: data
})


export const getUsers = (onSuccess, onErrors) => (dispatch) => {
    usersService.getUsers((response) => {
        dispatch(getUsersCompleted(response));
        onSuccess(response)
    }, onErrors);
};

export const editUser = (userInfo, onSuccess, onErrors) => (dispatch) => {
    usersService.editUser(userInfo, (response) => {
        dispatch(editUserCompleted(response));
        onSuccess(response)
    }, onErrors);
};

export const deleteUser = (username, onSuccess, onErrors) => (dispatch) => {
    usersService.deleteUser(username, (response) => {
        dispatch(deleteUserCompleted(response));
        onSuccess(response)
    }, onErrors);
};

export const addUser = (userInfo, onSuccess, onErrors) => (dispatch) => {
    usersService.addUser(userInfo, (response) => {
        dispatch(addUserCompleted(response));
        onSuccess(response)
    }, onErrors);
};

export const addUserCredits = (creditsInfo, onSuccess, onErrors) => (dispatch) => {
    usersService.addUserCredits(creditsInfo, (response) => {
        dispatch(addUserCreditsCompleted(response));
        onSuccess(response)
    }, onErrors);
};