import { config, appFetch } from "../appFetch";

export const getPageProducts = (page, verified, onSuccess) => appFetch(`/product/function::getPageProducts||page::${page}||limit::100`+(verified !== null ? `||verified::${verified}`: ""), config("GET"), onSuccess);
export const getProductById = (id, onSuccess) => appFetch(`/product/function::getById||idProduct::${id}`, config("GET"), onSuccess);

//export const searchProducts = (searchObj, onSuccess) => appFetch(`/product/function::searchProducts||title::${searchObj.title}||ean::${searchObj.ean}||sku::${searchObj.sku}`, config("GET"), onSuccess);

export const searchProducts = (searchString, onSuccess) => appFetch(
    `/product/function::searchProducts${searchString}`
, config("GET"), onSuccess);


export const purgeProduct = (productId, onSuccess) => appFetch(`/product/function::purgeProduct`, config("POST", { id: productId }), onSuccess);

export const addNewProduct = (formData, onSuccess) => appFetch(`/product`, config("POST", formData), onSuccess);

export const updateProduct = (productData, onSuccess) => appFetch(`/product/idProduct::${productData.idProduct}`, config("PUT", productData), onSuccess);

export const addNewProductTitle = (formData, onSuccess) => appFetch(`/title`, config("POST", formData), onSuccess);
export const updateProductTitle = (idTitle, newTitle, onSuccess) => appFetch(`/title/idTitle::${idTitle}`, config("PUT", { idTitle: idTitle, title: newTitle }), onSuccess);
export const deleteProductTitle = (idTitle, onSuccess) => appFetch(`/title/idTitle::${idTitle}`, config("DELETE"), onSuccess);

export const addNewProductDescription = (formData, onSuccess) => appFetch(`/description`, config("POST", formData), onSuccess);
export const updateProductDescription = (idDescription, newDescription, onSuccess) => appFetch(`/description/idDescription::${idDescription}`, config("PUT", { idDescription: idDescription, description: newDescription }), onSuccess);
export const deleteProductDescription = (idDescription, onSuccess) => appFetch(`/description/idDescription::${idDescription}`, config("DELETE"), onSuccess);

export const addNewProductImage = (formData, onSuccess) => appFetch(`/image`, config("POST", formData), onSuccess);
export const deleteProductImage = (idImage, onSuccess) => appFetch(`/image/idImage::${idImage}`, config("DELETE"), onSuccess);

export const deleteProduct = (idProduct, onSuccess) => appFetch(`/producttttt/idProduct::${idProduct}`, config("DELETE"), onSuccess);
