import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

const initialState = {
    loginInfo: {
        username: null
    },
}

const loginInfo = (state = initialState.loginInfo, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            return action.loginInfo;
        default:
            return state;
    }
};

const reducer = combineReducers({
    loginInfo
});

export default reducer;