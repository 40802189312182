import * as actionTypes from "./actionTypes";
import * as logsService from "../../api/services/logsService";

export const getLogsCompleted = (data) => ({
    type: actionTypes.GET_LOGS,
    pageLogs: data
})

export const getRequestsCompleted = (data) => ({
    type: actionTypes.GET_REQUESTS,
    userRequests: data.requests
})

export const getReportsCompleted = (data) => ({
    type: actionTypes.GET_REPORTS,
    pageReports: data
})

export const getNotFoundCompleted = (data) => ({
    type: actionTypes.GET_NOT_FOUND,
    pageNotFound: data
})

export const sendNotFoundFixedMailCompleted = (data) => ({
    type: actionTypes.UPDATE_NOT_FOUND_FIXED,
    idReport: data.idReport
})

export const getLogs = (searchParams, onSuccess, onErrors) => (dispatch) => {
    logsService.getLogs(searchParams, (response) => {
        dispatch(getLogsCompleted(response));
        onSuccess(response)
    }, onErrors);
};

export const getRequests = (onSuccess, onErrors) => (dispatch) => {
    logsService.getRequests((response) => {
        dispatch(getRequestsCompleted(response));
        onSuccess(response)
    }, onErrors);
};

export const getReports = (searchParams, onSuccess, onErrors) => (dispatch) => {
    logsService.getReports(searchParams, (response) => {
        dispatch(getReportsCompleted(response));
        onSuccess(response);
    }, onErrors);
}

export const getNotFound = (searchParams, onSuccess, onErrors) => (dispatch) => {
    logsService.getNotFound(searchParams, (response) => {
        dispatch(getNotFoundCompleted(response));
        onSuccess(response);
    }, onErrors);
}

export const sendNotFoundFixedMail = (idReport, onSuccess, onErrors) => (dispatch) => {
    logsService.sendNotFoundFixedMail(idReport, (response) => {
        dispatch(sendNotFoundFixedMailCompleted(response));
        onSuccess(response);
    }, onErrors);
}